.contact{
    background: var(--light-bg);
}

.contact form{
    padding: 2rem;
    background: var(--white);
    max-width: 50rem;
    margin: 0 auto;
    border-radius: .5rem;
}

.contact form span{
    font-size: 1.7rem;
    color: var(--primary);
}

.contact form .inputBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact form .inputBox input{
    padding: 1rem;
    margin: 1rem 0;
    font-size: 1.7rem;
    color: var(--black);
    text-transform: capitalize;
    background: var(--light-bg);
    border-radius: .5rem;
    width: 49%;
}

.contact form .box{
    margin: 1rem 0;
    width: 100%;
    background: var(--light-bg);
    border-radius: .5rem;
    padding: 1rem;
    font-size: 1.7rem;
    color: var(--black);
    text-transform: capitalize;
}