.services{
    background: var(--light-bg);
}

.services .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 2rem;
}

.services .box-container .box{
    padding: 4rem;
    text-align: center;
    background: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: 5rem;
}

.services .box-container .box:hover{
    background: var(--hover);
}

.services .box-container .box img{
    margin: 1rem 0;
    height: 15rem;
}

.services .box-container .box h3{
    font-size: 2rem;
    padding: 1rem 0;
    color: var(--black);
}

.services .box-container .box p{
    font-size: 1.5rem;
    color: var(--light-color);
    line-height: 2;
}
