.header{
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 1000;
    background: var(--white);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 9%;
}

.header .logo{
    font-size: 2.5rem;
    font-weight: bolder;
    color: var(--black);
}

.header .logo i{
    color: var(--primary);
    padding-right: .5rem;
}

.header .navbar a{
    font-size: 1.7rem;
    color: var(--black);
    margin: 0 1rem;
}

.header .navbar a:hover{
    color: var(--primary);
}

.header .btn{
    margin-top: 0;
}


#menu-btn{
    font-size: 2.5rem;
    cursor: pointer;
    color: var(--black);
    margin-left: 1.7rem;
    display: none;
}

#menu-btn:hover{
    color: var(--primary);
}