.home{
    min-height: 70vh;
    display: flex;
    align-items: center;
    background: url(../images/eee2.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.home .content{
    max-width: 60rem;
    padding-left: 6rem;
    padding-top: 10rem;
}

.home .content h3{
    
    font-size: 6rem;
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.home .content p{
    line-height: 2;
    font-size: 1.5rem;
    color: rgb(223, 216, 216);
    padding: 1rem 0;
}
