.utils_container{margin:auto;max-width:1440px}
.utils_container .container{height:50px;}


.bts_01-icon_list{display:flex;flex-wrap:wrap;justify-content:center;align-items:flex-start;gap:0 20rem}
.bts_01-icon_list .bts_01-icon_list-icon_wrapper{min-height:60px;display:flex;align-items:center}
.bts_01-icon_list.bts_01-icon_list-icon_item{display:flex;flex-direction:column;justify-content:center;align-items:center;width:362px;padding:0 15px}
.bts_01-icon_list .bts_01-icon_list-icon_item .bts_01-copy{margin-top:6px;text-align:center}
.bts_01-icon_list h4.bts_01-title{font-size:20px; text-align: center;}
.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:first-child:after,.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:last-child:after{content:"";position:absolute;width:1px;height:44px;background-color:#d0d0d0;top:0;bottom:0;margin:auto;margin-right:-18px}
.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:first-child:after{right:0;left:auto}
.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:last-child:after{right:auto;left:0;margin-left:-18px}
.bts_01-icon_list .bts_01-icon_list-number_container{position:relative}
.bts_01-icon_list .bts_01-icon_list-number_container .bts_01-icon_list-number{position:absolute;left:0;right:0;margin:auto;text-align:center;top:5px;font-weight:400;font-size:24px;line-height:36px;color:#0067b2}.bts_01-content{max-width:754px;margin:12px auto}  

.bts_01-icon_list .bts_01-icon_list-icon_item{
    padding:30px 18px;
    margin-bottom:0
}

.bts_01-icon_list-icon{
    border-style:none;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
img.mfp-img{
    width:auto;
    max-width:100%;
    height:auto;
    display:block;
    line-height:0;
    box-sizing:border-box;
    padding:40px 0 40px;
    margin:0 auto
}


.sn-spacer{background-color:#ffffff}
.sn-spacer.d30{height:25px}
