.about .row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.about .row .image{
    flex: 1 1 40rem;
}

.about .row .image img{
    width: 100%;
    height: 100%;
}

.about .row .content{
    flex: 1 1 40rem;
}

.about .row .content h3{
    color: var(--black);
    font-size: 3.5rem;
    line-height: 1.5;
}

.about .row .content p{
    font-size: 1.4rem;
    color: var(--light-color);
    padding: 1rem 0;
    line-height: 2;
}