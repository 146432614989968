@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

:root{
    --primary:#1f2e88;
    --secondary:#2f66d4;
    --hover: #a2d9f5;
    --black:#333;
    --white:#fff;
    --light-color:#666;
    --light-bg:#eee;
    --border:.2rem solid rgba(0,0,0,.1);
    --box-shadow:0 .5rem 1rem rgba(0,0,0,.1);
}

*{
    font-family: 'Poppins', sans-serif;
    margin: 0; padding: 0;
    box-sizing: border-box;
    outline: none; border: none;
    text-decoration: none;
    text-transform: capitalize;
    transition: .2s linear;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 9rem;
    scroll-behavior: smooth;
}

html::-webkit-scrollbar{
    width: .8rem;
}

html::-webkit-scrollbar-track{
    background: transparent;
}

html::-webkit-scrollbar-thumb{
    background: var(--primary);
    border-radius: 5rem;
}

section{
    padding: 5rem 7%;
}

.heading{
    font-size: 4rem;
    text-align: center;
    color: var(--black);
    text-transform: uppercase;
    font-weight: bolder;
    margin-bottom: 3rem;
}

.btn{
    margin-top: 1rem;
    display: inline-block;
    padding: 1rem 3rem;
    border-radius: .5rem;
    background: var(--primary);
    cursor: pointer;
    font-size: 1.7rem;
    color: var(--white);
}

.btn:hover{
    background: var(--secondary);
}

/* Blog  Page */

.blogPage{
    margin: 5% 20% 20px 20%;
}


.blogPage h1{
    text-align: center;
    font-size: 50px;
    color: #132e64;
}


.blogPage img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }

.para{
    text-align: center;
    font-size: 15px; 
}

.paras{
    color: #173268;
    text-align: center;
    font-size: 27px;
}

.blogPage h2{
    text-align: left;
    font-size: 25px;
    color: #132e64;

}

.parara {
    text-align: left;
    font-size: 17px; 
}

.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customers td, .customers th {
  border: 3px solid #ddd;
  padding: 10px;
}

.customers tr:nth-child(even){background-color: #f2f2f2;}

.customers tr:hover {background-color: #ddd;}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
.customers button {
  background-color: rgb(252, 15, 15);
  border-radius: 5px;
  padding: 5px;
}

.login {
  text-align: center;
}

h1 {
  text-align: center;
}

.login input {
  text-align: center;
}

ul {
    list-style-type: none;
    font-size: 15px;
    line-height: 1.9;
  }


@media (max-width:1088px){
    .bts_01-icon_list{
        flex-direction:column;
        align-items:center
    }
.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:first-child:after,.bts_01-icon_list.bts_01-icon_list-separation .bts_01-icon_list-icon_item:last-child:after{display:none}}


@media (max-width: 991px){

    html{
        font-size: 55%;
    }

    .header .btn{
        display: none;
    }

    section{
        padding: 2rem;
    }

    .blogPage{
        margin: 20% 5% 0px 5%;
    }  
    .blogPage h1{
        text-align: center;
        font-size: 34px;
        color: #132e64;
    } 

    .blogPage img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 250px;
      }

}

@media (max-width: 768px){

    #menu-btn{
        display: inline-block;
    }

    #menu-btn.fa-times{
        transform: rotate(180deg);
    }

    .header .navbar{
        position: absolute;
        top: 99%; left: 0; right: 0;
        background: var(--white);
        border-top: var(--border);
        padding: 1rem 0;
        text-align: center;
        flex-flow: column;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    .header .navbar.active{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .header .navbar a{
        display: block;
        padding: 1rem 0;
        font-size: 2rem;
    }

    .home{
        min-height: 50vh;
        background-position: left;
    }

    .home .content{
        max-width: 50rem;
        padding-left: 3rem;
    }

    .home .content h3{
        font-size: 4rem;
    }

    .blogPage{
        margin: 20% 5% 0px 5%;
    }  
    .blogPage h1{
        text-align: center;
        font-size: 34px;
        color: #132e64;
    } 

    .blogPage img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 250px;
      }

}

@media(max-width:450px){

    html{
        font-size: 50%;
    }

    .home .content h3{
        font-size: 3rem;
    }
    .blogPage{
        margin: 20% 5% 0px 5%;
    }  
    .blogPage h1{
        text-align: center;
        font-size: 34px;
        color: #132e64;
    }  

    .blogPage img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 250px;
      }

}