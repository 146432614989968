.pricing{
    background: rgb(219, 219, 219);
}

.pricing .box-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.pricing .box-container .box{
    height: 46rem;
    width: 30rem;
    margin: 2rem 4rem;
    border-radius: 5rem;
    box-shadow: var(--box-shadow);
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: var(--white);
}

.pricing .box-container .active{
    background: var(--hover);
}

.pricing .box-container .box .title{
    margin: 3rem 0;
    font-size: 2.2rem;
    color: var(--black);
    text-transform: uppercase;
}

.pricing .box-container .box .price{
    color: var(--primary);
    margin-bottom: 5rem;
}

.pricing .box-container .box .price .currency{
    font-size: 4rem;
    line-height: 3.5rem;
    font-weight: 300;
}

.pricing .box-container .box .price .amount{
    font-size: 5.8rem;
    line-height: 4.5rem;
    font-weight: 700;
}

.pricing .box-container .box .price .duration{
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: .2rem;
}

.pricing .box-container .box ul li{
    text-align: left;
    font-size: 1.6rem;
    padding-left: 3.5rem;
    margin: 2rem auto;
    color: var(--light-color);
    text-transform: uppercase;
}

.pricing .box-container .box ul li i{
    padding: 0 1rem;
    color: var(--secondary);
}