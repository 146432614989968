.blog .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 1.5rem;
}

.blog .box-container .box{
    border: var(--border);
}

.blog .box-container .box .image{
    height: 25rem;
    width: 100%;
    overflow: hidden;
}

.blog .box-container .box .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog .box-container .box:hover .image img{
    transform: scale(1.2);
}

.blog .box-container .box .content{
    padding: 2rem 1.5rem;
}

.blog .box-container .box .content .link{
    display: inline-block;
    margin-bottom: 3rem;
    padding: .6rem 1.5rem;
    background: var(--primary);
    font-size: 1.4rem;
    color: var(--white);
}

.blog .box-container .box .content .link:hover{
    background: var(--secondary);
}

.blog .box-container .box .content .icons{
    padding-bottom: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog .box-container .box .content .icons a{
    font-size: 1.4rem;
    color: var(--light-color);
}

.blog .box-container .box .content .icons a:hover{
    color: var(--primary);
}

.blog .box-container .box .content .icons a i{
    color: var(--primary);
    padding-right: .5rem;
}

.blog .box-container .box .content h3{
    font-size: 2.2rem;
    color: var(--black);
}

.blog .box-container .box .content p{
    padding: 1rem 0;
    font-size: 1.4rem;
    color: var(--light-color);
    line-height: 2;
}